import React, { useState, useEffect, useRef } from 'react'
import { message as Message } from 'antd'
import { parse, stringify } from 'qs'
import PageHeader from '@/components/PageHeader'

import { fetchOrderDetail } from '@/Service'

import OrderPageContainer from './components/OrderPageContainer'
import OrderMainInfo from './components/OrderMainInfo'
import OrderBaseInfo from './components/OrderBaseInfo'
import OrderExtraInfo from './components/OrderExtraInfo'
import OrderTips from './components/OrderTips'
import OrderPlaceholder from './components/OrderPlaceholder'

import OrderContext from './OrderContext'
import OrderCancellation from './components/OrderCancellation'
import ChoosePayment from './components/ChoosePayment'

function Order() {
  const { search } = window.location
  const query = parse((search || '').replace(/^\?/, '')) || {}
  const { id, device, callBack, to, price } = query
  const [orderInfo, setOrderInfo] = useState({})
  const isOrderCancelledRef = useRef(false)
  const idRef = useRef(id)
  const timerRef = useRef()
  const [isDone, setIsDone] = useState(false)
  const [noCallBack, setNoCallBack] = useState(false)

  const {
    submitNo,
    payName,
    callBackUrl,
    payIcon,
  } = orderInfo


  const done = (status) => {
    setIsDone(true)
    clearTimeout(timerRef.current)
    if (callBackUrl) {
      setTimeout(() => {
        window.location.replace(callBackUrl)
      }, 100)
    } else {
      setNoCallBack(true)
    }
  }

  const fetchDetail = async () => {
    const id = idRef.current
    if (id) {
      const res = await fetchOrderDetail({ id })
      const { data, message = 'sai lầm' } = res || {}
      if (data) {
        setOrderInfo((prevState) => ({ ...prevState, ...data }))
        return data
      }
      Message.error(message)
    }
    return Promise.reject(new Error('something wrong!'))
  }

  const handleError = (error) => {
    console.log(error)
    const { message } = error || {}
    if (String(message).toLowerCase().includes('network')) {
      Message.error('Đã xảy ra lỗi mạng, vui lòng thử làm mới trang')
      // next()
    } else if (String(message).toLowerCase().includes('timeout')) {
      done('CANCEL')
    } else {
      done('ERROR')
    }
  }

  const autoFetchDetail = () => {
    clearTimeout(timerRef.current)
    const next = () => {
      timerRef.current = setTimeout(() => {
        autoFetchDetail()
      }, 3000)
    }
    fetchDetail().then((res) => {
        if (window && window.document) {
          document.title = 'Quầy thanh toán'
        }
        const { orderStatus, id } = res || {}
        idRef.current = id
        // updateUrl()
        if (orderStatus === 'ORDER_STATUS_COMPLETE') {
          Message.success('Bộ sưu tập thành công', 3, () => done('SUCCESS'))
        } else if (
          String(orderStatus).includes('CANCEL') &&
          !isOrderCancelledRef.current
        ) {
          Message.error('Hủy đơn hàng', 3, () => done('CANCEL'))
          isOrderCancelledRef.current = true
        } else if (
          orderStatus === 'ORDER_STATUS_PAY_EXPIRED' &&
          !isOrderCancelledRef.current
        ) {
          Message.error('Hết thời gian đặt hàng', 3, () => done('TIMEOUT'))
          isOrderCancelledRef.current = true
        } else {
          next()
        }
      })
      .catch((error) => {
        handleError(error, next)
      })
  }

  useEffect(() => {
    autoFetchDetail();
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const getContextValue = () => ({
    orderInfo,
    device,
    icon:payIcon,
    to,
    from: callBack,
    refs: { isOrderCancelledRef, idRef },
    fns: { done, handleError },
  })

  return (
    <div className="container order">
      <PageHeader back={callBack} title="Quầy thanh toán" className="order-header" />
      {(() => {
        if (orderInfo.id) {
          if (isDone && noCallBack) {
            const { orderStatus } = orderInfo
            let status = 'error'
            let title
            title = 'Hết thời gian đặt hàng'
            if (String(orderStatus).includes('CANCEL')) {
              title = 'Hủy đơn hàng'
            }
            if (orderStatus === 'ORDER_STATUS_COMPLETE') {
              title = 'Thanh toán thành công'
              status = 'success'
            }
            return (
              <OrderPlaceholder
                visible={isDone}
                title={title}
                status={status}
              />
            )
          }
          return (
            <OrderContext.Provider value={getContextValue()}>
              <OrderPageContainer
                payName={payName}
                extra={<OrderExtraInfo />}
                base={<OrderBaseInfo />}
                tips={<OrderTips />}
              >
                <OrderMainInfo />
              </OrderPageContainer>
            </OrderContext.Provider>
          )
        }
        return <OrderPlaceholder visible={noCallBack} />
      })()}
    </div>
  )
}

export default Order
