import React, { useContext } from 'react'
import OrderContext from '../../OrderContext'
function OrderTips() {
  const { orderInfo } = useContext(OrderContext)
  return (
    <>
      <div className="mt-1" style={{lineHeight:'25px'}}>
      Hãy nhớ thanh toán theo số tiền {' '}
        <strong className="text-danger">
          {orderInfo.payablePrice}
        </strong>{' '}
        này, nếu không điểm sẽ không được tự động tăng lên, vui lòng không thanh toán nhiều lần!
      </div>
      <div className="text-danger text-bold size-3" style={{ marginTop: 12 }}>
        {orderInfo.merTips}
      </div>
    </>
  )
}

export default OrderTips
