import React, { useContext } from 'react'
import OrderContext from '../../OrderContext'
import { Button, message, Row, Col } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'

function OrderBaseInfo() {
  const { orderInfo } = useContext(OrderContext)
  return (
    <>
      <Row type="flex" align="middle" justify='space-between' gutter={12}>
        <Col className="size-2" >
          Số tiền thanh toán： <span  className="text-danger text-bold">{orderInfo.payablePrice}</span>
        </Col>
        <Col>
          <CopyToClipboard
            text={orderInfo.payablePrice}
            onCopy={(_, result) =>
              message.success(`Sao chép${result ? 'thành công' : 'thất bại'}`)
            }
          >
            <Button type="primary">Sao chép</Button>
          </CopyToClipboard>
        </Col>
      </Row>

      <Row type="flex" align="middle" justify='space-between' gutter={12} style={{marginTop:'10px',marginBottom:'10px'}}>
        <Col className="size-2">
          Số đơn hàng：<span  className="text-bold">{orderInfo.orderCode}</span>
        </Col>
        <Col>
          <CopyToClipboard
            text={orderInfo.orderCode}
            onCopy={(_, result) =>
              message.success(`Sao chép${result ? 'thành công' : 'thất bại'}`)
            }
          >
            <Button type="primary">Sao chép</Button>
          </CopyToClipboard>
        </Col>
      </Row>

      {orderInfo.payTimeOut>0&&(
            <Row type="flex" align="middle" justify='space-between' gutter={12}  style={{marginTop:'10px'}}>
              <Col className="size-2" >
                Thời gian hết hạn： <span  className="text-danger text-bold">{formatCountdown(orderInfo.payTimeOut)}</span>
              </Col>
            </Row>
        )}
    </>
  )
}
function formatCountdown(seconds: number): string {
  if (typeof seconds !== 'number' || isNaN(seconds)) return '';
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  if (hours > 0) {
    const formattedHours = hours.toString().padStart(2, '0');
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = secs.toString().padStart(2, '0');
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  } else if (minutes > 0) {
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = secs.toString().padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  } else {
    return secs.toString();
  }
}
export default OrderBaseInfo
