import React from 'react'
import { Button, Result } from 'antd'
import Spin from '@/components/Spin'

function OrderPlaceholder({
  visible,
  status = 'error',
  title = 'Đơn hàng chưa được đặt hoặc đã xảy ra lỗi',
}) {
  return visible ? (
    <Result
      status={status}
      title={title}
      style={{ margin: 'auto', color: '#fff' }}
      extra={
        <Button
          type="primary"
          key="console"
          onClick={() => {
            window.history.back()
          }}
        >
          trở lại
        </Button>
      }
    />
  ) : (
    <Spin />
  )
}

export default OrderPlaceholder
